<template>
  <FocusTrap>
    <div style=" padding: 5px;">
      <div class="table-responsive">
        <table id="mytable" class="table table-no-bordered">
          <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th style="width:30px;" scope="col">#</th>
              <th class="hidden" style="width: 0px">id</th>
              <th style="width:90px;" scope="col">Bale No</th>
              <th scope="col">Quality</th>
              <th style="width:100px; text-align: right;" scope="col">Pcs</th>
              <th style="width:100px; text-align: right;" scope="col">Qty</th>
            </tr>
          </thead>
          <tbody v-for="(bale,index) in bales" >
            <tr>
              <td>{{index+1}}</td>
              <td style="width:30px;"><input type="checkbox" v-model="bale.selected" ></td>
              <td class="hidden" style="width: 0px">{{bale.bale_id}}</td>
              <td>{{bale.bale_no}}</td>
              <td>{{bale.quality}}</td>
              <td style="width:100px; text-align: right;">{{bale.pcs}}</td>
              <td style="width:100px; text-align: right;">{{bale.qty}}</td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <p/>
      <p/>
      <p/>
      <div class="d-flex justify-content-end" style="padding-right: 5px;">
        <button class="btn btn-outline-success" @click="on_update_button_click">Update</button>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'BaleList',
  components: {  },
  store,
  props: {

    mybales: {
      type: Array,
      default: () => []
    },
    myindex: {
      type: Number,
      default: () => 0
    },
  },
  data () {
    return {
      index: 0,
      bale : {"bale_id":0,"order_id":0,"bale_no":"","pcs":0,"quality_id":0,"quality":"","hsn":"","gst_rate":5,"price":0,"qty":0,"weight":0,"taxable_amt":0},
      bales : [],
    }
  },
  created () {
    const self = this;

  },
  beforeMount () {
    const self = this;
    self.bales = self.mybales;
  },
  mounted () {
    const self = this;
  },
  methods: {
    on_update_button_click(){
      this.$emit('bales_selected');
    }
  }

}
</script>

<style scoped>
  input {
    text-align: right;
  }

  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00!important;
    color: #0a0a0a!important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
