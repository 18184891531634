<template>
  <tr style="padding: 0px;">
    <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

    <td style="padding: 0px;">
      <select class="form-control" v-if="detail" v-model="detail.quality_id" @change="rowValueEqualizer(index, 1)" disabled>
        <option v-for="quality in myqualities" v-bind:value="quality.id">
          {{ quality.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;text-align: center;">
      {{detail.size}}
    </td>

    <td style="padding: 0px; width: 30px;" >
      <select class="form-control text-center" v-if="detail" v-model="detail.gst_rate" @change="rowValueEqualizer(index, 2)" disabled>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="5">5</option>
        <option value="12">12</option>
        <option value="18">18</option>
        <option value="28">28</option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 3)" />
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 4)" disabled/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.weight" @change="rowValueEqualizer(index, 5)" disabled/>
    </td>


    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.taxable_amt" @change="rowValueEqualizer(index, 6)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.packingcharge" @change="rowValueEqualizer(index, 7)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.igst_amt" :disabled="!isIntegrated"  @change="rowValueEqualizer(index, 8)" disabled/>
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.cgst_amt" :disabled="!isState" @change="rowValueEqualizer(index, 9)"/>
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.sgst_amt" :disabled="!isState" @change="rowValueEqualizer(index, 10)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.net_amt" readonly />
    </td>

    <td style="padding: 0px;text-align: right;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import ItemBox from '@/components/core/ItemBox.vue'
import numeral from 'numeral'
export default {
  name: 'SaleInvoiceDetailRow',
  store,
  components: {
    ItemBox,
  },
  props: {
    myrow: {
      type: Object,
      default: () => JSON.parse('{"idx":0,"order_id":1,"bale_id":1,"item_id":0,"unit_id":1,"hsn":"","size":"","itemtype":"","gst_rate":0,"quality_id":0,"quality":{"id":0,"name":""},"item":{"id":0,"nature":0,"name":"","group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":0,"name":"","digits":0},"subunit":{"id":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"unit":{"id":0,"name":"","digits":0},"size":"","rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":0,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"packingcharge":0,"discount_amt":0,"taxable_amt":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"round_off":0,"net_amt":0}')
    },
    myindex: {
      type: Number,
      default: () => 0
    },
    items:{
      type: Array,
      default: () => new Array()
    },
    myqualities:{
      type: Array,
      default: () => new Array()
    },
    vchtype:{
      default: () => 0
    },
    state:{
      type: Number,
      default: () => 0
    }
  },
  data (){
    return {
      detail: JSON.parse('{"idx":0,"order_id":1,"bale_id":1,"item_id":0,"unit_id":1,"hsn":"","size":"","itemtype":"","gst_rate":0,"quality_id":0,"quality":{"id":0,"name":""},"item":{"id":0,"nature":0,"name":"","group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":0,"name":"","digits":0},"subunit":{"id":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"unit":{"id":0,"name":"","digits":0},"size":"","rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":0,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"packingcharge":0,"discount_amt":0,"taxable_amt":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"round_off":0,"net_amt":0}'),
      index: 0,
      isIntegrated: true,
      isState: false
    }
  },
  beforeMount () {
    this.detail = this.myrow;
    this.index = this.myindex;

    // isIntegrated: true,
    // isState: false

    this.detail.packingcharge = this.detail.packingcharge.toFixed(2);
    this.detail.taxable_amt = this.detail.taxable_amt.toFixed(2);

    this.detail.rate = this.detail.rate.toFixed(2);
    this.detail.igst_amt = this.detail.igst_amt.toFixed(2);
    this.detail.cgst_amt = this.detail.cgst_amt.toFixed(2);
    this.detail.sgst_amt = this.detail.sgst_amt.toFixed(2);
    this.detail.net_amt = this.detail.net_amt.toFixed(2);

  },
  mounted(){
    const self = this;

    if (this.$props.state == 0 || (this.$props.state == store.state.user.branch.state_cd)) {
      this.$data.isIntegrated = false;
      this.$data.isState =  true;
    }else{
      this.$data.isIntegrated = true;
      this.$data.isState =  false;
    }
  },
  methods:{
    onItemChanged(item){
      const self = this;
      self.$props.myqualities.forEach( (quality) => {
        if(self.$data.detail.quality_id == quality.id) {
          self.$data.detail.quality = quality;
        }
      });
      self.rowValueEqualizer(self.$data.detail.idx , 1);
    },
    removeRow(idx){
      this.$emit('onRemoveRow', idx );
    },
    calculate_gst(detail){
      const self = this;
      try {
        let taxrate = detail.gst_rate;

        let taxable_amt = parseFloat( detail.packingcharge ) + (detail.qty * detail.rate);
        taxable_amt = self.calculate_taxable_amount();

        let tax_value = Math.round(taxable_amt * ((taxrate / 100) * 100));

        if (tax_value % 2 !== 0) {
          tax_value++;
        }
        tax_value = tax_value / 100;

        detail.tax_amt = tax_value;
        if (this.$props.state == 0 || (this.$props.state == store.state.user.company.state_cd)) {
          detail.igst_amt = 0;
          detail.sgst_amt = tax_value / 2;
          detail.cgst_amt = tax_value / 2;
        } else {
          detail.igst_amt = tax_value;
          detail.sgst_amt = 0;
          detail.cgst_amt = 0;
        }
      }catch (e) {
        alert(e);
      }

    },
    checkIGST(){

      if (this.$props.state == 0 || (this.$props.state == store.state.user.branch.state_cd)) {
        this.$data.isIntegrated = false;
        this.$data.isState =  true;
      }else{
        this.$data.isIntegrated = true;
        this.$data.isState =  false;
      }
    },
    calculate_taxable_amount() {
      const self = this;
      let taxable_amount = 0.0;
      try{

        self.$props.myqualities.forEach( (quality) => {
          if(self.$data.detail.quality_id == quality.id) {
            self.$data.detail.quality = quality;
          }
        });

        if(self.$data.detail.quality.rate_based_on == 0 ) {
          //taxable_amount = (self.$data.detail.qty * self.$data.detail.rate) + parseFloat(self.$data.detail.packingcharge);
          taxable_amount = (self.$data.detail.qty * self.$data.detail.rate) - self.$data.detail.discount_amt;
          self.$data.detail.taxable_amt = numeral(taxable_amount).format('0.00');
        }else {
          //taxable_amount = (self.$data.detail.weight * self.$data.detail.rate) + parseFloat(self.$data.detail.packingcharge);
          taxable_amount = (self.$data.detail.weight * self.$data.detail.rate) - self.$data.detail.discount_amt;
          self.$data.detail.taxable_amt = numeral(taxable_amount).format('0.00');
        }

      }catch (e) {
        alert(e);
      }
      return taxable_amount;
    },
    rowValueEqualizer(rowIndex, colIndex) {
      const self = this;
      let tax_value = 0;

      let detail = self.$data.detail;
      switch (colIndex) {
        case 1:
          self.$props.myqualities.forEach( (quality) => {
            if(self.$data.detail.quality_id == quality.id) {
              self.$data.detail.quality = quality;
            }
          });
          self.calculate_taxable_amount();
          self.calculate_gst(detail);
          break;
        case 2: //GST Rate
          detail.taxable_amt = self.calculate_taxable_amount();
          //detail.rate = numeral(parseFloat(detail.taxable_amt) / parseFloat(detail.qty) ).format('0.00');
          detail.rate =  numeral(parseFloat((detail.taxable_amt + self.$data.detail.discount_amt)) / parseFloat(detail.qty) ).format('0.00');

          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');

          break;
        case 3://Product Rate

          //detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
          detail.taxable_amt = self.calculate_taxable_amount();
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');

          break;
        case 4://Product Qty
          detail.taxable_amt = self.calculate_taxable_amount();
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;

        case 5://Product Weight
          let taxable3 = self.calculate_taxable_amount();
          detail.taxable_amt = numeral( taxable3 ).format('0.00');
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;
        case 6://Taxable value
          detail.rate = numeral(  (detail.taxable_amt + detail.discount_amt)  / parseFloat(detail.qty) ).format('0.00');
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;
        case 7: //packingcharge
          let taxable2 = self.calculate_taxable_amount();
          detail.taxable_amt = taxable2; //numeral( taxable2 ).format('0.00');
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;
        case 8:
        case 9:
        case 10:
          detail.tax_amt = numeral(parseFloat(detail.igst_amt) + parseFloat(detail.cgst_amt) + parseFloat(detail.sgst_amt)).format('0.00');
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;
      }
      self.$emit('on_cell_value_change',rowIndex, colIndex);

    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
