<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="voucher.id == 0" class="card-title">Sale Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Sale Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Buyer</label>
              <select id="cmbledger" ref="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher"  v-model="voucher.buyer_id" @change="enableAddButton" >
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Type</label>
              <select class="form-control select" required="" v-if="voucher"  v-model="voucher.type" @change="enableAddButton" >
                <option value="404">B2B - Local</option>
                <option value="405">B2C - Local</option>
                <option value="406">Export</option>
                <option value="407">B2B - Other State</option>
                <option value="408">B2C - Other State</option>
                <option value="409">B2C - Local Exempted</option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Order</label>
              <select class="form-control" required="" v-model="saleorder_id" @change="loadOrderBales"  >
                <option value="1" selected>Stock Bale</option>
                <option v-for="saleorder in saleorders" v-bind:value="saleorder.id">
                  {{ saleorder.doc_no }} - {{ saleorder.buyer.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">

              <label>.</label>
              <button id="btnBale" class="form-control btn btn-outline-success" @click="loadOrderBales()">Bales</button>

            </div>
          </div>

          <div class="col-md-3">
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Ref No</label>
              <input ref="txtRef" type="text" class="form-control" placeholder="Ref No" minlength="0" maxlength="30" v-if="voucher" v-model="voucher.ref_no">
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label  font-weight-semibold">Invoice Date</label>
              <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" placeholder="Invoice Date" >
            </div>
          </div>

        </div>

        <div class="row">
          <!--  Detail Table -->
          <div class="table-responsive">

            <table id="mytable" class="table table-no-bordered">
              <thead style="background-color: lightgrey">
                <tr>
                <th style="width:50px;">S.No</th>
                <th >Quality</th>
                <th style="text-align: center;">Size</th>
                <th style="width:60px; text-align: right;">GST%</th>
                <th style="width:100px; text-align: right;">Rate</th>
                <th style="width:75px; text-align: right;">Qty</th>
                <th style="width:75px; text-align: right;">Weight</th>
                <th style="width:100px; text-align: right;">Taxable</th>
                <th style="width:75px; text-align: right;">Packing Charge</th>
                <th style="width:100px; text-align: right;">IGST</th>
                <th style="width:100px; text-align: right;">CGST</th>
                <th style="width:100px; text-align: right;">SGST</th>
                <th style="width:150px; text-align: right;">Net Amount</th>
                <th style="width: 30px;">Action</th>
              </tr>
              </thead>
              <tbody v-if="voucher" v-for="(detail,index) in voucher.list" >
                <InvoiceDetail :state="voucher.buyer.state_cd" :vchtype="voucher.type"  :items="detailItems" :myqualities="qualities"   :myindex="index" :myrow="detail" v-on:on_cell_value_change="cell_value_change" v-on:onRemoveRow="removeRow"  > </InvoiceDetail>
              </tbody>
              <tfoot>
              <tr>
                <td>
                  <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow"  :disabled="addRowButtonDisabled">
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" ><span style="">{{ rowQtyTotal }}</span></td>
                <td class="text-right" ><span style="">{{ rowWeightTotal.toFixed(3) }}</span></td>
                <td class="text-right" >&#8377;<span style="">{{ indianFormat(rowTaxableTotal) }}</span></td>
                <td class="text-right" >&#8377;<span style="">{{ indianFormat(rowPackingchargeTotal) }}</span></td>
                <td class="text-right" >&#8377;<span style="">{{ indianFormat(rowIgstTotal) }}</span></td>
                <td class="text-right" >&#8377;<span style="">{{ indianFormat(rowCgstTotal) }}</span></td>
                <td class="text-right" >&#8377;<span style="">{{ indianFormat(rowSgstTotal) }}</span></td>
                <td class="text-right" >&#8377;<span style="">{{ indianFormat(rowAmountTotal) }}</span></td>

                <td></td>


              </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="row" style="background-color: whitesmoke;">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-3">
            <h3 style="font-size: 48px;color: blue">
              <!--voucher.gross_amt-->
              &#8377;<span style="padding-right: 15px;">{{ indianFormat( voucher.net_amt ) }}</span>
            </h3>
          </div>

          <div class="col-md-4" >
            <div class="form-group row">
              <div class="col-md-6">
                <div class="form-group form-group-material">
                  <label class="control-label font-weight-semibold">Transport</label>
                  <input class="form-control" placeholder="Transport Name"  v-if="voucher" v-model="voucher.transport" maxlength="75" />
                </div>

                <div class="form-group form-group-material">
                  <label class="control-label font-weight-semibold">Station</label>
                  <input class="form-control" placeholder="Booked to Station Name"  v-if="voucher" v-model="voucher.station" maxlength="75" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group form-group-material">
                  <label class="control-label font-weight-semibold">LR No</label>
                  <input class="form-control" placeholder="LR No"  v-if="voucher" v-model="voucher.lr_no" maxlength="20" />
                </div>

                <div class="form-group form-group-material">
                  <label class="control-label font-weight-semibold">LR Date</label>
                  <input type="date" class="form-control" v-if="voucher" v-model="voucher.lr_date" />
                </div>
              </div>

            </div>
          </div>


          <div class="col-md-2 text-right">

            <div class="form-group form-group-material text-left">
              <label class="control-label font-weight-semibold">Packing Charges</label>
              <input type="number" class="form-control text-right" placeholder="Packing Charges" v-if="voucher" v-model="voucher.packingcharges" @change="sumRowAmountTotal"  disabled>
            </div>

            <div class="form-group form-group-material text-left">
              <label class="control-label font-weight-semibold">Round Off</label>
              <input id="txtroundoff" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="voucher" v-model="voucher.round_off" @change="sumRowAmountTotal" >
            </div>

            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
            <button id="btnclose" type="button" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
          </div>
        </div>

      </div>

      <FocusTrap>
        <vmodal name="bale_window" :delay="100" :resizable="true" :width="550" height="auto" maxHeight="600" scrollable="true" >
          <div v-if="bales_loaded" >
              <BaleList v-bind:mybales="bales" v-on:bales_selected="on_update_button_click" />
          </div>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>

import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import numeral from 'numeral'
import GstPanel from '@/components/v1/gst/GstPanel.vue'
import InvoiceDetail from './SaleInvoiceDetailRow.vue'
import { store } from '@/store/store.js'
import BaleList from './BaleList'

export default {
  name: 'SaleInvoiceForm',
  components: {
    BaleList,
    InvoiceDetail
  },
  store,
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}')
    }
  },
  beforeMount () {
    const self = this;
    const requestOptions = {
      method: 'GET',
      mode:"cors",
      headers: userService.authHeader()
    };

    if( this.myvoucher.id > 0 ) {
      var data = [];
      const request = async () => {
        const response = await fetch(`${process.env.VUE_APP_ROOT_API}api/items`, requestOptions);

        const json = await response.json();
        data = json.data;
        if (_.isArray(data)) {
          data.forEach(function (itm) {
            self.$data.items.set(itm.id, itm);
          });
        }


        //Remove the Packing Charge Item from List
        this.myvoucher.list.forEach( (det, index) => {
          if(det.item_id === 268) {
            self.myvoucher.list.splice(index, 1);
            //self.voucher.list.remove(det);
          }
        });

        this.voucher = this.myvoucher;

        this.saleorder_id = this.voucher.saleorder_id;

        this.voucher.round_off = this.voucher.round_off.toFixed(2);
        self.loadActiveSaleOrders();

        setTimeout(function(){
          self.loadOrderBales();
          self.sumRowAmountTotal();
        }, 500);

      }
      request();

      self.$data.addRowButtonDisabled = false;
    }
  },
  data () {
    return {
      addRowButtonDisabled:true,
      readonly: false,
      ledgers:[],
      detailItems:[],
      saleorder_id: 1,
      saleorders : [],
      items : new Map(),
      rowQtyTotal : 0.0,
      rowWeightTotal : 0.0,
      rowAmountTotal : 0.0,
      rowIgstTotal : 0.0,
      rowCgstTotal : 0.0,
      rowSgstTotal : 0.0,
      rowTaxableTotal : 0.0,
      rowPackingchargeTotal : 0.0,
      voucher : JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}'),
      bales: [],
      bales_loaded: false,
      qualities : [],
    }
  },
  created () {
    const self = this;

    self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;
    self.loadData();

    self.$data.addRowButtonDisabled = true;
    if (self.$data.voucher.doc_date === '0001-01-01' || self.$data.voucher.doc_date === '2000-01-01'){
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    }
    self.loadQualities();
    // self.loadActiveSaleOrders();

    if (self.$data.voucher.lr_date === '0001-01-01' || self.$data.voucher.lr_date === '2000-01-01'){
      self.$data.voucher.lr_date = moment().format('YYYY-MM-DD');
    }

    self.$refs.cmbledger.focus();
  },
  methods:{
    showBaleWindow() {
      this.$modal.show('bale_window');
    },
    indianFormat(val){
      return parseFloat(val).toFixed(2);
    },
    on_update_button_click() {

      const self = this;

      this.$data.voucher.list = [];
      this.$modal.hide('bale_window');

      setTimeout(function(){
        self.display_rows();
        self.$refs.txtRef.focus();
      }, 99);

      self.$refs.txtRef.focus();
    },
    closeThis(){
      this.$emit('saleinvoice_window_closed');
      if(this.voucher.id > 1){
        this.voucher = JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}');
      }else{
        this.$router.push("/")
      }
    },
    loadActiveSaleOrders(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.saleorders = [];
      // alert(`${process.env.VUE_APP_ROOT_API}api/saleorders/${self.voucher.buyer_id}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/saleorders/state/Active/${self.voucher.buyer_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          if (_.isArray(resp.data)) {
            self.saleorders = resp.data;
          }

        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
    loadOrderBales(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      try {

        // alert(`${process.env.VUE_APP_ROOT_API}api/sale/items/${self.saleorder_id}`);

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.rowPackingchargeTotal = 0;

        if (self.$data.voucher.id == 0) {
          self.$data.voucher.list = [];
          self.bales = [];
          self.bales_loaded = false;

          self.$data.rowQtyTotal = 0.0;
          self.$data.rowAmountTotal = 0.0;
          self.$data.rowIgstTotal = 0.0;
          self.$data.rowCgstTotal = 0.0;
          self.$data.rowSgstTotal = 0.0;
          self.$data.rowTaxableTotal = 0.0;
          self.$data.rowPackingchargeTotal = 0;

        }

        if (self.saleorder_id > 0) {

          fetch(`${process.env.VUE_APP_ROOT_API}api/sale/items/${self.saleorder_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {
              if (_.isArray(resp.data)) {
                self.bales_loaded = true;
                self.bales = resp.data;

                console.log(resp.data);
                console.log(JSON.stringify(resp.data));

                self.showBaleWindow();
              }
            } else {
              swal({ title: 'Oops!', text: resp.msg, type: 'error', timer: 3000 })
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer: 3000 })
          }).finally(function () {
            $('#mycard').unblock();
            // self.display_rows();
          });
        } else {
          $('#mycard').unblock();
        }
      }catch (e) {
        alert(e);
      }
    },
    display_rows(){
      try {
        const self = this;

        self.$data.voucher.list = [];

        self.$data.rowWeightTotal = 0.0;
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.rowPackingchargeTotal = 0.0;

        self.bales.forEach(function (bale) {

          if(bale.selected) {

            let line = JSON.parse('{"idx":0,"order_id":1,"bale_id":1,"item_id":0,"unit_id":1,"hsn":"","size":"","itemtype":"","gst_rate":0,"quality_id":0,"quality":{"id":0,"name":""},"item":{"id":0,"nature":0,"name":"","group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":0,"name":"","digits":0},"subunit":{"id":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"unit":{"id":0,"name":"","digits":0},"size":"","rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":0,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"packingcharge":0,"discount_amt":0,"taxable_amt":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"round_off":0,"net_amt":0}');

            line.gst_rate = bale.gst_rate;
            line.order_id = self.saleorder_id;
            line.bale_id = bale.bale_id;
            line.unit_id = bale.unit_id;

            //line.item.id = bale.item_id;
            //line.item.name = bale.item_name;
            line.quality_id = bale.quality_id;
            line.quality_name = bale.quality_name;
            line.size = bale.size;
            line.hsn = bale.hsn;
            line.itemtype = bale.itemtype;
            line.qty_txt = bale.qty;
            line.rate = parseFloat(bale.price);
            line.qty = parseFloat(bale.qty);
            line.weight = parseFloat(bale.weight);
            line.discount_amt  = 0;
            line.packingcharge = parseFloat(line.packingcharge);

            let taxable_amount = 0.0;
            if(bale.rate_based_on == 0 ) {
              taxable_amount = parseFloat(bale.qty) * parseFloat(bale.price);
            }else {
              taxable_amount =parseFloat(bale.weight) * parseFloat(bale.price);
            }

            line.taxable_amt = taxable_amount;
            line.igst_amt = parseFloat(taxable_amount) * (bale.gst_rate / 100);
            line.net_amt = parseFloat(taxable_amount) + parseFloat(line.igst_amt);

            self.$data.rowQtyTotal += parseFloat(bale.qty);
            self.$data.rowAmountTotal += line.taxable_amt;
            self.$data.rowIgstTotal += line.igst_amt;
            self.$data.rowCgstTotal += line.cgst_amt;
            self.$data.rowSgstTotal += line.sgst_amt;
            self.$data.rowTaxableTotal += line.net_amt;
            self.$data.rowPackingchargeTotal += line.packingcharge;

            self.$data.voucher.list.push(line);
          }
        });

        self.sumRowAmountTotal();
      }catch (e) {
        alert(e);
      }
    },
    cell_change(row, col) {
      alert( row + "   -    " + col);
    },
    enableAddButton(){
      const self = this;
      try {
        self.loadActiveSaleOrders();
        this.ledgers.forEach(function (ldgr) {
          if (self.$data.voucher.buyer_id == ldgr.id) {
            self.$data.voucher.buyer = JSON.parse(JSON.stringify(ldgr));
          }
        });

        this.$data.voucher.list = [];
        this.$data.addRowButtonDisabled = (this.$data.voucher.buyer_id > 1 && this.$data.voucher.type == 0);
      }catch (e) {
        alert(e);
      }
    },
    addRow(){
      try {
        const self = this;
        self.$data.voucher.list.push(JSON.parse('{"idx":0,"order_id":1,"bale_id":1,"item_id":0,"unit_id":1,"hsn":"","size":"","itemtype":"","gst_rate":0,"quality_id":0,"quality":{"id":0,"name":""},"item":{"id":0,"nature":0,"name":"","group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":0,"name":"","digits":0},"subunit":{"id":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"unit":{"id":0,"name":"","digits":0},"size":"","rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":0,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"packingcharge":0,"discount_amt":0,"taxable_amt":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"round_off":0,"net_amt":0}'));

        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);

      }catch (e) {
        alert(e);
      }
    },
    cell_value_change(rowIndex, colIndex) {
      const self = this;
      self.sumRowAmountTotal();
    },
    removeRow(index){
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
        this.sumRowAmountTotal();
      }
    },
    sumRowAmountTotal() {
      const self = this;

      try {

        self.voucher.net_amt = 0;
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowWeightTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.rowPackingchargeTotal = 0;

        self.$data.voucher.round_off = parseFloat(self.$data.voucher.round_off);
        self.$data.voucher.packingcharges = 0;


        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowWeightTotal  += parseFloat(detail.weight);
          self.$data.rowPackingchargeTotal += parseFloat(detail.packingcharge);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowIgstTotal += parseFloat(detail.igst_amt);
          self.$data.rowSgstTotal += parseFloat(detail.sgst_amt);
          self.$data.rowCgstTotal += parseFloat(detail.cgst_amt);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);

          self.$data.voucher.packingcharges  += parseFloat(detail.packingcharge);
        });

        self.voucher.net_amt = self.rowAmountTotal + self.voucher.round_off + self.calculate_packingcharges().net_amt;

      }catch (e) {
        alert(e);
      }

    },
    clear(){
      $('#btnSave').prop('disabled', false);
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":407,"finyear":2023,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"saleorder_id":0,"buyer":{"id":0,"type":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","lr_no":"","lr_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amt":0,"disc_amt":0,"taxable_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"tds_amt":0,"round_off":0,"packingcharges":0,"net_amt":0,"irn":"","ack_no":"","ack_date":"0001-01-01","list":[]}');
      self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
      self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
      self.$data.voucher.list = [];
      self.$data.rowAmountTotal = 0.0;
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      self.$data.addRowButtonDisabled = true;
      self.$data.rowQtyTotal = 0.0;
      self.$data.rowWeightTotal = 0.0;
      self.$data.rowPackingchargeTotal = 0.0;
      self.$data.rowAmountTotal = 0.0;
      self.$data.rowIgstTotal = 0.0;
      self.$data.rowCgstTotal = 0.0;
      self.$data.rowSgstTotal = 0.0;
      self.$data.rowTaxableTotal = 0.0;
      self.$data.rowTaxableTotal = 0.0;

      $('#cmbledger').focus();
    },
    loadData() {
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      //fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/buyers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(_.isArray(resp.data)){
            self.$data.ledgers = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });


      if( self.$data.items.size == 0 ){
        //Fetch Detail Ledgers
        self.$data.detailItems = []
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}api/items`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( _.isArray(resp.data)){
              self.$data.detailItems = resp.data;
              self.$data.detailItems.forEach(function (itm) {
                self.$data.items.set(itm.id, itm);
              });
            }
            $(".select-search").select2();

          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      }
    },
    loadQualities () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.qualities = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.qualities = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    printPreview(){
      const self = this;
      self.$data.filename = 'invoice.mrt';

      if(self.$data.voucher.itemtype == 2) {
        self.$data.filename = 'invoice_2.mrt';
      }

      self.$data.reppath =  `${process.env.VUE_APP_ROOT_API}api/sale/${self.$data.voucher.id}`;

      self.$data.can_print = true;

      $('#fsPrintPreview').modal('show');
    },
    calculate_packingcharges(){
      const self = this;
      const packingcharges_id = 268;
      //Add Packing Charges
      let packingCharges_row = {"idx":0,"order_id":1,"bale_id":1,"item_id":0,"unit_id":1,"hsn":"","size":"","itemtype":"","gst_rate":0,"quality_id":0,"quality":{"id":0,"name":""},"item":{"id":0,"nature":0,"name":"","group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":0,"name":"","digits":0},"subunit":{"id":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"unit":{"id":0,"name":"","digits":0},"size":"","rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":0,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"packingcharge":0,"discount_amt":0,"taxable_amt":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"round_off":0,"net_amt":0};
      try{
        packingCharges_row.idx = self.$data.voucher.list.length;
        packingCharges_row.order_id = parseInt(self.saleorder_id);
        packingCharges_row.hsn = "60011090";
        packingCharges_row.gst_rate = 5;
        packingCharges_row.quality_id = 1;
        packingCharges_row.color_id = 1;
        packingCharges_row.item.id = packingcharges_id;
        packingCharges_row.item_id = packingcharges_id;
        packingCharges_row.unit.id = 1;
        packingCharges_row.qty = 1;
        packingCharges_row.qty_txt = 1;
        packingCharges_row.weight = 0;
        packingCharges_row.rate = self.$data.voucher.packingcharges;
        packingCharges_row.taxable_amt = self.$data.voucher.packingcharges;

        packingCharges_row.tax_amt = (packingCharges_row.taxable_amt * 5) / 100;

        if (self.$data.voucher.buyer.state_cd == 0 || (self.$data.voucher.buyer.state_cd == store.state.user.company.state_cd)) {
          packingCharges_row.igst_amt = 0;
          packingCharges_row.sgst_amt = packingCharges_row.tax_amt / 2;
          packingCharges_row.cgst_amt = packingCharges_row.tax_amt / 2;
        } else {
          packingCharges_row.igst_amt = packingCharges_row.tax_amt;
          packingCharges_row.sgst_amt = 0;
          packingCharges_row.cgst_amt = 0;
        }
        packingCharges_row.net_amt = self.$data.voucher.packingcharges + packingCharges_row.tax_amt;

      }catch (e) {
        alert(e);
      }
      return packingCharges_row;
    },
    saveVoucher(){
      const self = this;

      let valid_data = true;
      const packingcharges_id = 268;


      try{

        if(self.$data.voucher.net_amt <= 0){
          valid_data = false;
          alert("Invoice Amount");
          return;
        }else if(self.$data.voucher.packingcharges <= 0){
          valid_data = false;
          alert("Packing Charges");
          return;
        }


        let taxamt = 0;
        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.status = "Active";
        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.type = parseInt(self.$data.voucher.type);
        myvoucher.eway_date = moment().format('YYYY-MM-DD');
        myvoucher.eway_bil_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD');

        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');


        myvoucher.gross_amt = 0;
        myvoucher.taxable_amt = 0;
        myvoucher.disc_amt = 0;
        myvoucher.igst_amt = 0;
        myvoucher.cgst_amt = 0;
        myvoucher.sgst_amt = 0;
        myvoucher.saleorder_id = parseInt(self.saleorder_id);

        myvoucher.list.push(self.calculate_packingcharges());

        myvoucher.list.forEach(function (detail){

            if(detail.hsn == null ||  detail.hsn.trim().length == 0 ) {
              detail.hsn = "60011090";
            }
            detail.order_id = parseInt(self.saleorder_id);
            detail.bale_id = parseInt(detail.bale_id);
            detail.gst_rate = parseFloat(detail.gst_rate);
            detail.qty_txt = detail.qty.toString();
            detail.qty = parseFloat(detail.qty);

            if(detail.itemtype == 1) {
              if(detail.unit_id == null || detail.unit_id < 1) {
                detail.unit_id = 3; //Unit -> Mtrs
              }
            }else {
              if(detail.unit_id == null || detail.unit_id < 1) {
                detail.unit_id = 1; //Unit -> Pcs
              }
            }

            detail.rate = parseFloat(detail.rate);
            detail.taxable_amt = parseFloat(detail.taxable_amt);
            detail.igst_amt = parseFloat(detail.igst_amt);
            detail.cgst_amt = parseFloat(detail.cgst_amt);
            detail.sgst_amt = parseFloat(detail.sgst_amt);
            detail.tax_amt = parseFloat(detail.tax_amt);
            detail.net_amt = parseFloat(detail.net_amt);

            taxamt += detail.cgst_amt + detail.sgst_amt + detail.igst_amt;
            myvoucher.gross_amt += parseFloat(detail.taxable_amt) + parseFloat(detail.discount_amt);
            myvoucher.disc_amt += parseFloat(detail.discount_amt);

            myvoucher.taxable_amt += parseFloat(detail.taxable_amt);
            myvoucher.igst_amt += parseFloat(detail.igst_amt);
            myvoucher.cgst_amt += parseFloat(detail.cgst_amt);
            myvoucher.sgst_amt += parseFloat(detail.sgst_amt);

            //Validate the Packing Charges
            if(detail.item.id != packingcharges_id) {
              if (detail.bale_id == null || detail.bale_id < 1) {
                valid_data = false;
                alert("Invalid Bale loaded");
                return;
              } else if (detail.packingcharge <= 0) {
                valid_data = false;
                alert("Invalid Bale Packing Charge");
                return;
              }
            }
        });

        myvoucher.round_off = parseFloat(self.$data.voucher.round_off);
        myvoucher.net_amt = myvoucher.taxable_amt + taxamt + self.voucher.round_off ;// + self.$data.voucher.packingcharges;

        // console.log(JSON.stringify(myvoucher));
        // valid_data = false;

        const requestOptions = {
          method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT' ),
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        if(valid_data) {

          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });


          $('#btnSave').prop('disabled', true);
          fetch(`${process.env.VUE_APP_ROOT_API}api/sale`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {
              swal({ title: 'Great', type: 'success', text: resp.msg });
              self.$emit('sale_invoice_saved');
              $('#mycard').unblock();
            } else {
              $('#btnSave').prop('disabled', false);
              $('#mycard').unblock();
              swal({ title: "Oops", text: resp.msg, type: "error" });
            }
          }).catch(function (err) {
            $('#mycard').unblock();
            $('#btnSave').prop('disabled', false);
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }

      }catch (e) {
        alert(e);

      }finally {

      }



    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
